import React, { FC } from "react";
import styled from "styled-components";
import { MediaAsset } from "../../types";

import { toVWMobile, toVWDesktop, toREM } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import Link from "../shared/Link";

export type IngredientsLocatorProps = {
  isPublished?: boolean;
  title: string;
  cards: CardType[];
};

type CardType = {
  image: MediaAsset;
  primaryTitle: string;
  secondaryTitle: string;
  description: string;
  primaryButton: Button;
  secondaryButton: Button;
};

type Button = {
  label: string;
  link: string;
};

const IngredientsLocator: FC<IngredientsLocatorProps> = ({
  isPublished,
  title,
  cards,
}: IngredientsLocatorProps) => {
  if (!isPublished) return null;

  return (
    <Container>
      <Title>{title}</Title>
      <CardContainer>
        {cards?.map((card: CardType, index) => (
          <Card key={index}>
            <PictureContainer>
              <Picture src={card?.image?.src} alt={card?.image?.alt} />
            </PictureContainer>
            <CardTitleWrapper>
              <CardTitle
                dangerouslySetInnerHTML={{
                  __html: card?.primaryTitle,
                }}
              />
            </CardTitleWrapper>
            <CardDescription>{card?.description}</CardDescription>
            <ButtonWrapper>
              <PrimaryButton to={card?.primaryButton?.link}>
                {card?.primaryButton?.label}
              </PrimaryButton>
              <SecondaryButton to={card?.secondaryButton?.link}>
                {card?.secondaryButton.label}
              </SecondaryButton>
            </ButtonWrapper>
          </Card>
        ))}
      </CardContainer>
    </Container>
  );
};

const Container = styled.section`
  background-color: #033305;
  padding: ${toVWMobile(49)}vw ${toVWMobile(24)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(92)}vw ${toVWDesktop(99)}vw ${toVWDesktop(108)}vw;
  }
`;

const Title = styled.h3`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(0.36)}rem;
  line-height: ${toVWMobile(40)}vw;
  margin-bottom: ${toVWMobile(111)}vw;
  text-transform: uppercase;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(48)}rem;
    letter-spacing: ${toREM(0.58)}rem;
    line-height: normal;
    margin: 0 auto ${toVWDesktop(164)}vw;
    max-width: ${toVWDesktop(894)}vw;
    text-align: center;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
  }
`;

const Card = styled.div`
  background-color: #012102;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 ${toVWMobile(24)}vw ${toVWMobile(24)}vw;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: ${toVWMobile(90)}vw;

    @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
      margin-bottom: 0;
      margin-right: ${(props) =>
        props.theme.isRTL ? 0 : `${toVWDesktop(20)}vw`};
      margin-left: ${(props) =>
        props.theme.isRTL ? `${toVWDesktop(20)}vw` : 0};
    }
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    max-width: ${toVWDesktop(400)}vw;
    padding: 0 ${toVWDesktop(24)}vw ${toVWDesktop(39)}vw;
    width: ${toVWDesktop(400)}vw;
  }
`;

const PictureContainer = styled.div`
  display: flex;
  align-self: center;
  height: ${toVWMobile(135)}vw;
  margin: ${toVWMobile(0)}vw auto ${toVWMobile(30)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(165)}vw;
    margin: ${toVWDesktop(0)}vw auto ${toVWDesktop(10)}vw;
  }
`;

const Picture = styled.img`
  height: ${toVWMobile(185)}vw;
  object-fit: contain;
  position: relative;
  top: ${toVWMobile(-50)}vw;
  width: ${toVWMobile(230)}vw;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(230)}vw;
    top: ${toVWDesktop(-85)}vw;
    width: ${toVWDesktop(290)}vw;
  }
`;

const CardTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: column;
  }
`;

const CardTitle = styled.h4`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  font-weight: 900;
  font-style: normal;
  letter-spacing: ${toREM(0.72)}rem;
  line-height: ${toVWMobile(28)}vw;
  margin-right: ${toVWMobile(5)}vw;
  text-transform: uppercase;

  strong {
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(36)}rem;
    line-height: ${toVWDesktop(40)}vw;
    margin-right: 0;
  }
`;

const CardDescription = styled.p`
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  font-weight: 600;
  font-style: normal;
  margin-bottom: ${toVWMobile(28)}vw;
  margin-top: ${toVWMobile(8)}vw;
  letter-spacing: ${toREM(0.54)}rem;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    letter-spacing: normal;
    line-height: ${toVWDesktop(24)}vw;
    margin-bottom: ${toVWDesktop(28)}vw;
    margin-top: ${toVWDesktop(14)}vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const PrimaryButton = styled(Link)`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #75c154;
  height: ${toVWMobile(57)}vw;
  color: #000000;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(16)}rem;
  font-weight: 700;
  font-style: normal;
  margin-bottom: ${toVWMobile(15)}vw;
  text-align: center;
  text-decoration: none;
  padding: 0 ${toVWMobile(10)}vw;
  letter-spacing: ${toREM(0.16)}rem;
  transition: transform 0.5s;
  width: 100%;

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    height: ${toVWDesktop(56)}vw;
    padding: 0 ${toVWDesktop(12)}vw;
    margin-bottom: 0;
    width: 47%;

    &:hover {
      cursor: pointer;
      transform: scale(0.9, 0.9);
    }
  }
`;

const SecondaryButton = styled(PrimaryButton)`
  background-color: #ffffff;
  margin-bottom: 0;
`;

export default IngredientsLocator;
