import * as React from "react";
import styled from "styled-components";
import BurgerBackgroundImage from "../../assets/images/burger.png";
import { toREM, toVWDesktop, toVWMobile } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import {
  PARALLAX_BURGER_ID,
  PARALLAX_CONTAINER_ID,
  useParallax,
} from "./useParallax";

export type BeyondBurgerProps = {
  isPublished?: boolean;
  title: string;
  description: string;
  backgroundText: string;
};

const BeyondBurger: React.FC<BeyondBurgerProps> = ({
  isPublished,
  title,
  description,
  backgroundText,
}: BeyondBurgerProps) => {
  if (!isPublished) return null;
  useParallax();

  return (
    <Container id={PARALLAX_CONTAINER_ID}>
      <MainText
        fontColor={"#FFFFFF"}
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Description>{description}</Description>

      <ImageContainer>
        <BackgroundText>{backgroundText}</BackgroundText>
        <Image
          id={PARALLAX_BURGER_ID}
          src={BurgerBackgroundImage}
          alt={"Burger"}
        />
      </ImageContainer>
    </Container>
  );
};

const Container = styled.section`
  width: 100%;
  background-color: #012102;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${toVWMobile(40)}vw ${toVWMobile(0)}vw ${toVWMobile(71)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(34)}vw ${toVWDesktop(0)}vw ${toVWDesktop(66)}vw;
  }
`;

const MainText = styled.h2<{ fontColor: string }>`
  width: 100%;
  text-align: center;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(44)}rem;
  line-height: ${toREM(53)}rem;
  font-weight: 900;
  letter-spacing: 0.012em;
  color: ${(props) => props.fontColor};
  text-transform: uppercase;
  padding: ${toVWMobile(0)}vw ${toVWMobile(20)}vw;

  strong {
    color: #75c154;
    font-weight: inherit;
  }

  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(0)}vw ${toVWDesktop(20)}vw;
    font-size: ${toREM(80)}rem;
    line-height: ${toREM(88)}rem;
    font-weight: 900;
    letter-spacing: 0.03em;
  }
`;

const Description = styled.p`
  width: 100%;
  text-align: center;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  font-weight: 600;
  letter-spacing: 0em;
  color: #ffffff;
  padding: ${toVWMobile(0)}vw ${toVWMobile(24)}vw;
  padding-top: ${toVWMobile(8)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(0)}vw ${toVWDesktop(408)}vw;
    padding-top: ${toVWDesktop(8)}vw;
    font-size: ${toREM(18)}rem;
    line-height: ${toREM(28)}rem;
    font-weight: 600;
    letter-spacing: 0em;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  height: ${toVWMobile(255)}vw;
  margin-top: ${toVWMobile(28)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    margin-top: ${toVWDesktop(48)}vw;
    height: ${toVWDesktop(696)}vw;
  }
`;

const BackgroundText = styled.div`
  width: 100%;
  text-align: center;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(97)}rem;
  line-height: ${toREM(22)}rem;
  font-weight: 900;
  letter-spacing: 0.012em;
  color: #ffffff;
  opacity: 0.2;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${toREM(324)}rem;
    line-height: ${toREM(74)}rem;
    font-weight: 900;
    letter-spacing: 0.07em;
  }
`;

const Image = styled.img`
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

export default BeyondBurger;
