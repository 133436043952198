import * as React from "react";
import styled from "styled-components";
import handleViewport from "react-in-viewport";
import { Parallax } from "react-scroll-parallax";
import { Image as ImageType } from "../../types";
import {
  toPixelsFromVW,
  toREM,
  toVWDesktop,
  toVWMobile,
} from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";
import { useEffect } from "react";
import { getLimits } from "../../helpers/parallax";

export type IngredientDetailProps = {
  backgroundTitle: string;
  title: string;
  subtitle: string;
  description: string;
  backgroundColor: string;
  primaryFontColor: string;
  secondaryFontColor: string;
  mobileImage?: ImageType;
  desktopImage?: ImageType;
  inViewport?: boolean;
  onEnterViewport?: () => void;
  onLeaveViewport?: () => void;
  active: boolean;
};

const getizeRatio = (text: string) => {
  if (text?.length > 10) {
    return 0.75;
  }
  return 1;
};

const IngredientDetail = ({
  backgroundTitle,
  title,
  subtitle,
  description,
  backgroundColor,
  primaryFontColor,
  secondaryFontColor,
  mobileImage,
  desktopImage,
  inViewport,
  forwardedRef,
  active,
}: IngredientDetailProps) => {
  useEffect(() => {
    if (active) {
      forwardedRef?.current?.scrollIntoView({
        behavior: "smooth",
        inline: "nearest",
      });
    }
  }, [active]);

  return (
    <Container
      className="viewport-block"
      ref={forwardedRef}
      backgroundColor={backgroundColor}
    >
      <SectionTitle
        fontColor={secondaryFontColor}
        ratio={getizeRatio(backgroundTitle)}
      >
        {backgroundTitle}
      </SectionTitle>
      <TextContainer>
        <Title fontColor={primaryFontColor}>{title}</Title>
        <Subtitle fontColor={primaryFontColor}>{subtitle}</Subtitle>
        <Description fontColor={primaryFontColor}>{description}</Description>
      </TextContainer>
      <ParallaxImage
        y={getLimits(
          [`-${toPixelsFromVW(10)}px`, `${toPixelsFromVW(25)}px`],
          ["0px", "0px"]
        )}
      >
        <ImageMobile src={mobileImage?.src} alt={mobileImage?.alt} />
      </ParallaxImage>
      <ParallaxImage
        y={getLimits(
          ["0px", "0px"],
          [`-${toPixelsFromVW(5)}px`, `${toPixelsFromVW(5)}px`]
        )}
      >
        <ImageDesktop src={desktopImage?.src} alt={desktopImage?.alt} />
      </ParallaxImage>
    </Container>
  );
};

const Container = styled.section<{ backgroundColor: string }>`
  direction: ltr;
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${toVWMobile(57.5)}vw ${toVWMobile(19)}vw ${toVWMobile(60.5)}vw;
  position: relative;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding: ${toVWDesktop(0)}vw ${toVWDesktop(237)}vw ${toVWDesktop(345)}vw;
  }
`;

const SectionTitle = styled.h2<{ fontColor: string; ratio: number }>`
  width: fit-content;
  text-align: center;
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${(props) => props.ratio * toREM(61)}rem;
  font-weight: 900;
  letter-spacing: 0.06em;
  color: ${(props) => props.fontColor};
  opacity: 0.2;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    font-size: ${(props) => props.ratio * toREM(214)}rem;
    letter-spacing: 0.08em;
  }
`;

const TextContainer = styled.div`
  direction: ${(props) => (props.theme.isRTL ? "rtl" : "ltr")};
  display: flex;
  flex-direction: column;
  max-width: ${toVWMobile(260)}vw;
  padding-top: ${toVWMobile(306)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(61)}vw;
    width: ${toVWDesktop(368)}vw;
    max-width: ${toVWDesktop(368)}vw;
    align-self: flex-end;
  }
`;

const Title = styled.h3<{ fontColor: string }>`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(24)}rem;
  line-height: ${toREM(60)}rem;
  font-weight: 700;
  letter-spacing: 0.06em;
  color: ${(props) => props.fontColor};
  text-transform: uppercase;
`;

const Subtitle = styled.h4<{ fontColor: string }>`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(36)}rem;
  line-height: ${toREM(44)}rem;
  font-weight: 900;
  letter-spacing: 0.012em;
  color: ${(props) => props.fontColor};
  padding-top: ${toVWMobile(17)}vw;
  text-transform: uppercase;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(12)}vw;
    font-size: ${toREM(48)}rem;
    line-height: ${toREM(56)}rem;
    letter-spacing: 0.03em;
  }
`;

const Description = styled.p<{ fontColor: string }>`
  font-family: ${(props) => props.theme.fontFamily},
    ${(props) => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(18)}rem;
  line-height: ${toREM(28)}rem;
  font-weight: 600;
  letter-spacing: 0.012em;
  color: ${(props) => props.fontColor};
  padding-top: ${toVWMobile(17)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    padding-top: ${toVWDesktop(12)}vw;
    font-size: ${toREM(18)}rem;
    line-height: ${toREM(28)}rem;
    font-weight: 600;
    letter-spacing: 0;
  }
`;

const Image = styled.img`
  pointer-events: none;
`;

const ImageMobile = styled(Image)`
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: none;
  }
`;

const ParallaxImage = styled(Parallax)`
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  & img {
    width: 100%;
    height: auto;
  }
`;

const ImageDesktop = styled(Image)`
  display: none;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: block;
  }
`;

export default handleViewport(IngredientDetail, {
  threshold: 0.3,
});
