import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useEffect } from "react";
import {
  addParallaxElement,
  addParallaxRootElement,
  removeParallaxElement,
  removeParallaxRootElement,
} from "../../store/parallax";
import { isMobile } from "react-device-detect";
import { ParallaxTransformations } from "../../helpers/parallax";
import { toPixelsFromVW, toVWDesktop, toVWMobile } from "../../helpers/styles";

export const PARALLAX_CONTAINER_ID = "INGREDIENTS_BURGER";
export const PARALLAX_BURGER_ID = "PARALLAX_BURGER_ID";

export const useParallax = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const parallaxRootElement = document.querySelector(
      `#${PARALLAX_CONTAINER_ID}`
    );
    if (parallaxRootElement) {
      dispatch(
        addParallaxRootElement({
          key: PARALLAX_CONTAINER_ID,
          element: {
            root: parallaxRootElement,
            rect: parallaxRootElement.getBoundingClientRect(),
            elements: {},
          },
        })
      );
    }

    const parallaxElement = document.querySelector(`#${PARALLAX_BURGER_ID}`);
    if (parallaxElement) {
      dispatch(
        addParallaxElement({
          element: {
            element: parallaxElement,
            animations: [
              {
                unit: "",
                from: 1,
                to: isMobile ? 0.7 : 0.3,
                transformation: ParallaxTransformations.SCALE,
              },
              {
                unit: "px",
                from: isMobile
                  ? toPixelsFromVW(toVWMobile(0))
                  : toPixelsFromVW(toVWDesktop(0)),
                to: isMobile
                  ? toPixelsFromVW(toVWMobile(600))
                  : toPixelsFromVW(toVWDesktop(1370)),
                transformation: ParallaxTransformations.TRANSLATE_Y,
              },
            ],
          },
          rootKey: PARALLAX_CONTAINER_ID,
          key: PARALLAX_BURGER_ID,
        })
      );
    }
    return () => {
      parallaxRootElement &&
        dispatch(removeParallaxRootElement(PARALLAX_CONTAINER_ID));
      parallaxElement &&
        dispatch(
          removeParallaxElement({
            key: PARALLAX_BURGER_ID,
            rootKey: PARALLAX_CONTAINER_ID,
          })
        );
    };
  }, []);
};
