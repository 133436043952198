import * as React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { toREM, toVWDesktop } from "../../helpers/styles";
import { BREAKPOINTS } from "../../helpers/theme";

export type Step = {
  label: string;
  active?: boolean;
  activeColor: string;
  activeFontColor: string;
  nonActiveColor: string;
};

export type StepsProps = {
  visible: boolean;
  activeStep?: Step;
  steps: Array<Step>;
  onClick: (step: Step) => void;
};

const Steps: React.FC<StepsProps> = ({
  visible,
  steps,
  onClick,
  activeStep,
}: StepsProps) => {
  const { opacity } = useSpring({
    opacity: visible ? 1 : 0,
  });

  return (
    <Container aria-hidden={!visible} style={{ opacity }}>
      {steps.map((step, i) => {
        const activeElement = activeStep || {
          label: "",
          nonActiveColor: "#ffffff",
          activeFontColor: "#ffffff",
          activeColor: "transparent",
        };
        const isActive = activeElement?.label === step?.label;
        return (
          <StepContainer key={step.label} onClick={() => onClick(step)}>
            <StepText
              isActive={isActive}
              fontColor={
                isActive
                  ? activeElement?.activeColor
                  : activeElement?.nonActiveColor
              }
            >
              {step.label}
            </StepText>
            <StepCircle
              borderColor={
                isActive
                  ? activeElement?.activeColor
                  : activeElement?.nonActiveColor
              }
              backgroundColor={
                isActive ? activeElement?.activeColor : "transparent"
              }
              fontColor={
                isActive
                  ? activeElement?.activeFontColor
                  : activeElement?.nonActiveColor
              }
            >
              <StepNumber>{i + 1}</StepNumber>
            </StepCircle>
          </StepContainer>
        );
      })}
    </Container>
  );
};

const Container = styled(animated.div)`
  display: none;
  position: fixed;
  background-color: transparent;
  top: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  right: ${toVWDesktop(100)}vw;
  @media (min-width: ${BREAKPOINTS.DESKTOP}px) {
    display: flex;
  }
`;

const StepContainer = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  direction: ltr;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: ${toVWDesktop(13)}vw;
  padding-bottom: ${toVWDesktop(13)}vw;
`;

const StepText = styled.div<{
  fontColor: string;
  isActive: boolean;
}>`
  text-transform: uppercase;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  font-size: ${toREM(14)}rem;
  line-height: ${toREM(28)}rem;
  letter-spacing: 0.012em;
  font-weight: ${(props) => (props.isActive ? 800 : 600)};
  color: ${(props) => props.fontColor};
  padding-right: ${toVWDesktop(15)}vw;
`;

const StepCircle = styled.div<{
  borderColor: string;
  fontColor: string;
  backgroundColor: string;
}>`
  border-radius: 50%;
  font-weight: 700;
  font-family: ${props => props.theme.fontFamily}, ${props => props.theme.fallBackFontFamily}, sans-serif;
  border: ${toVWDesktop(2)}vw solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${toVWDesktop(20)}vw;
  width: ${toVWDesktop(20)}vw;
  color: ${(props) => props?.fontColor};
  font-size: ${toVWDesktop(12)}vw;
  letter-spacing: 0.012em;
  position: relative;
`;

const StepNumber = styled.span`
  position: absolute;
  top: ${toVWDesktop(2)}vw;
  height: ${toVWDesktop(12)}vw;
`;

export default Steps;
