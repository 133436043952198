import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import { useIntl } from "../../hooks/useIntl";
import Loadable from "@loadable/component";
import Page from "../../components/Page";
import Steps, { Step } from "../../components/Steps";
import BeyondBurger, { BeyondBurgerProps } from "../../components/BeyondBurger";
import IngredientDetail from "../../components/IngredientDetail";
import IngredientsLocator, {
  IngredientsLocatorProps,
} from "../../components/IngredientsLocator";
import { getTranslations } from "../../helpers/translations";
import { getAssetURL } from "../../helpers/assets";
import { Image } from "../../types";
import HowItsMade, { HowItsMadeProps } from "../../components/HowItsMade";
import { toVWDesktop } from "../../helpers/styles";
import { ExpandedIngredientsProps } from "../../components/ExpandedIngredients";
const ExpandedIngredients = Loadable(
  () => import("../../components/ExpandedIngredients")
);

import { isMobile } from "react-device-detect";
import SEO from "../../components/SEO";
import { parseSEO } from "../../helpers/seo";
import { isPublished } from "../../helpers/directus";
import { useStaticLabels } from "../../hooks/useStaticLabels";
import { ROUTES } from "../../helpers/routes";

type Section = {
  step: {
    label: string;
    activeColor: string;
    nonActiveColor: string;
    activeFontColor: string;
  };
  section: {
    backgroundTitle: string;
    title: string;
    subtitle: string;
    description: string;
    primaryFontColor: string;
    secondaryFontColor: string;
    backgroundColor: string;
    desktopImage: Image;
    mobileImage: Image;
  };
};

type SectionsVisibility = {
  [key: string]: boolean;
};

const IngredientsPage = ({ data }: { data: any }) => {
  const intl = useIntl();
  const labels = useStaticLabels();
  const [isAutoScrollEnabled, setAutoScroll] = useState<boolean>(true);
  const [sectionsVisibility, setSectionsVisibility] = useState<{
    [key: string]: boolean;
  }>({});
  const [sections, setSections] = useState<Array<Section>>([]);

  const getRootTranslatedData = useCallback(() => {
    return getTranslations(
      data,
      "directus.ingredients_page.translations",
      intl.locale
    );
  }, [data]);

  const getHowItsMadeData = useCallback((): HowItsMadeProps | null => {
    const translatedData = getRootTranslatedData();

    if (!isPublished(translatedData?.how_its_made_status)) return null;

    return {
      title: translatedData?.how_its_made_title,
      description: translatedData?.how_its_made_description,
      image1: {
        src: getAssetURL(translatedData?.how_its_made_medium_image),
        alt: translatedData?.how_its_made_medium_image_alt,
      },
      image2: {
        src: getAssetURL(translatedData?.how_its_made_small_image),
        alt: translatedData?.how_its_made_small_image_alt,
      },
      image3: {
        src: getAssetURL(translatedData?.how_its_made_large_image),
        alt: translatedData?.how_its_made_large_image_alt,
      },
      ctaLabel: translatedData?.how_its_made_button_label,
      ctaLink: translatedData?.how_its_made_button_link,
    };
  }, [getRootTranslatedData, intl]);

  const getIngredientsLocatorData = useCallback((): IngredientsLocatorProps => {
    const translatedData = getRootTranslatedData();

    return {
      isPublished: isPublished(translatedData?.locator_status),
      title: translatedData?.locator_title,
      cards: translatedData?.locator_products?.map(
        ({ product_page_id }: { product_page_id: any }) => {
          const { product = [] } =
            getTranslations(product_page_id, "translations", intl.locale) || {};

          const { variants = [], description } =
            getTranslations(product, "translations", intl.locale) || {};

          const translatedVariant = variants
            ?.map(({ product_variant_id }: { product_variant_id: any }) => {
              const variant =
                getTranslations(
                  product_variant_id,
                  "translations",
                  intl.locale
                ) || {};
              const variant_slug = product_variant_id?.variant_slug;
              return { ...variant, variant_slug };
            })
            ?.find(({ status }: { status: string }) => isPublished(status));

          return {
            image: {
              src: getAssetURL(translatedVariant?.small_image),
              alt: translatedVariant?.small_image_alt,
            },
            primaryTitle: translatedVariant?.title,
            description,
            primaryButton: {
              label: labels?.productDetails,
              link: `${ROUTES.PRODUCT_DETAIL}/${product_page_id?.product_page_slug}/${translatedVariant?.variant_slug}`,
            },
            secondaryButton: {
              label: labels?.findNearMe,
              link: ROUTES.WHERE_TO_FIND,
            },
          };
        }
      ),
    };
  }, [getRootTranslatedData, intl]);

  const getBeyondBurgerData = useCallback((): BeyondBurgerProps => {
    const translatedData = getRootTranslatedData();

    return {
      isPublished: isPublished(translatedData?.ingredients_status),
      title: translatedData?.ingredients_title,
      description: translatedData?.ingredients_description,
      backgroundText: translatedData?.ingredients_beyond_background,
    };
  }, [getRootTranslatedData, intl]);

  const getExpandedIngredientsData =
    useCallback((): ExpandedIngredientsProps => {
      const translatedData = getRootTranslatedData();

      return {
        isPublished: isPublished(translatedData?.ingredients_status),
        title: translatedData?.ingredients_ingredients_background,
      };
    }, [getRootTranslatedData, intl]);

  const getIngredientsDetail = useCallback((): Section[] => {
    const translatedData = getRootTranslatedData();

    return translatedData?.ingredients_content?.map(
      ({ each_ingredient_detail_id }: { each_ingredient_detail_id: any }) => {
        const e = getTranslations(
          each_ingredient_detail_id,
          "translations",
          intl.locale
        );

        return {
          section: {
            backgroundTitle: e?.background_title,
            title: e?.title,
            subtitle: e?.subtitle,
            description: e?.description,
            primaryFontColor: e?.primary_font_color,
            secondaryFontColor: e?.secondary_font_color,
            backgroundColor: e?.background_color,
            desktopImage: {
              src: getAssetURL(e?.desktop_image),
              alt: e?.desktop_image_alt,
            },
            mobileImage: {
              src: getAssetURL(e?.mobile_image),
              alt: e?.mobile_image_alt,
            },
          },
          step: {
            label: e?.background_title,
            activeColor: e?.primary_font_color,
            activeFontColor: e?.background_color,
            nonActiveColor: e?.menu_font_color,
          },
        } as Section;
      }
    );
  }, [getRootTranslatedData, intl]);

  const updateSectionVisibility = useCallback(
    (section: Section | undefined, active: boolean, resetOthers?: boolean) => {
      if (!section) {
        return null;
      }
      let visibility: SectionsVisibility | null = null;
      if (resetOthers) {
        visibility = {};
        Object.keys(sectionsVisibility).forEach((k) => {
          visibility[k] = false;
        });
      }
      setSectionsVisibility({
        ...(visibility || sectionsVisibility),
        [section?.section?.title]: active,
      });
    },
    [setSectionsVisibility, sectionsVisibility]
  );

  const handleStepClick = useCallback(
    (step: Step, disableAutoScroll = false) => {
      if (disableAutoScroll) setAutoScroll(false);
      updateSectionVisibility(
        sections?.find((s) => s?.step?.label === step?.label),
        true,
        true
      );
      if (disableAutoScroll) setTimeout(() => setAutoScroll(true), 1000);
    },
    [updateSectionVisibility, sections]
  );

  const handleEnterViewPort = useCallback(
    (section: Section | undefined) => {
      if (!section) {
        return;
      }
      updateSectionVisibility(section, true);
    },
    [updateSectionVisibility]
  );

  const handleLeaveViewPort = useCallback(
    (section: Section | undefined) => {
      if (!section) {
        return;
      }
      updateSectionVisibility(section, false);
    },
    [updateSectionVisibility]
  );

  const activeSection = useMemo((): Section | undefined => {
    const entry = Object.entries(sectionsVisibility).find(([k, v]) => !!v);
    if (!entry) {
      return undefined;
    }
    return sections.find((s) => s?.section?.title === entry[0]);
  }, [sectionsVisibility, sections]);

  const areStepsVisible = useMemo(() => {
    const entries = Object.entries(sectionsVisibility).filter(([k, v]) => !!v);
    return entries.length > 0;
  }, [sectionsVisibility]);

  useEffect(() => {
    const sections = getIngredientsDetail();
    setSections(sections);
    const visibility: SectionsVisibility = {};
    sections.forEach((s) => {
      visibility[s.section.title] = false;
    });
    setSectionsVisibility(visibility);
  }, [getIngredientsDetail]);

  return (
    <Page
      headerBackgroundColor={"#012102"}
      helmet={<SEO {...parseSEO(getRootTranslatedData(), intl?.locale)} />}
      displayMailing={true}
    >
      <>
        <BeyondBurger {...getBeyondBurgerData()} />
        <ExpandedIngredients {...getExpandedIngredientsData()} />
        {sections.map((s, i) => (
          <IngredientDetail
            key={s.section.title}
            backgroundTitle={s.section.backgroundTitle}
            title={s.section.title}
            subtitle={s.section.subtitle}
            description={s.section.description}
            backgroundColor={s.section.backgroundColor}
            primaryFontColor={s.section.primaryFontColor}
            secondaryFontColor={s.section.secondaryFontColor}
            mobileImage={s.section.mobileImage}
            desktopImage={s.section.desktopImage}
            onLeaveViewport={() => handleLeaveViewPort(s)}
            onEnterViewport={() => {
              if (!isAutoScrollEnabled || isMobile) return;
              handleEnterViewPort(s);
            }}
            active={s?.section.title === activeSection?.section?.title}
          />
        ))}
        <Steps
          activeStep={activeSection?.step}
          visible={areStepsVisible}
          steps={sections.map((s) => s.step)}
          onClick={(step: Step) => handleStepClick(step, true)}
        />
      </>
      <HowItsMade {...getHowItsMadeData()} desktopTopStyle={toVWDesktop(-65)} />
      <IngredientsLocator {...getIngredientsLocatorData()} />
    </Page>
  );
};

export default IngredientsPage;

export const query = graphql`
  query ($locale: String) {
    directus {
      ingredients_page {
        translations(filter: { languages_code: { code: { _eq: $locale } } }) {
          seo_information {
            ...SEO
          }
          id
          how_its_made_button_link
          how_its_made_description
          ingredients_description
          ingredients_title
          languages_code {
            code
            name
          }
          status
          ingredients_status
          how_its_made_status
          locator_status
          ingredients_beyond_background
          ingredients_ingredients_background
          how_its_made_title
          how_its_made_button_label
          how_its_made_small_image_alt
          how_its_made_medium_image_alt
          how_its_made_large_image_alt
          locator_title
          how_its_made_small_image {
            id
            filename_disk
          }
          how_its_made_medium_image {
            id
            filename_disk
          }
          how_its_made_large_image {
            id
            filename_disk
          }
          ingredients_content {
            each_ingredient_detail_id {
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                description
                background_title
                status
                title
                subtitle
                primary_font_color
                secondary_font_color
                background_color
                menu_font_color
                desktop_image_alt
                mobile_image_alt
                mobile_image {
                  id
                  filename_disk
                }
                desktop_image {
                  id
                  filename_disk
                }
              }
            }
          }
          locator_products {
            product_page_id {
              product_page_slug
              translations(
                filter: { languages_code: { code: { _eq: $locale } } }
              ) {
                languages_code {
                  code
                  name
                }
                variants_status
                product {
                  translations(
                    filter: { languages_code: { code: { _eq: $locale } } }
                  ) {
                    languages_code {
                      code
                      name
                    }
                    product_name
                    description
                    variants {
                      product_variant_id {
                        variant_slug
                        translations(
                          filter: { languages_code: { code: { _eq: $locale } } }
                        ) {
                          languages_code {
                            code
                            name
                          }
                          status
                          title
                          description
                          small_image_alt
                          small_image {
                            id
                            filename_disk
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
